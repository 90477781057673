<script>
import MetOClient from "@fmidev/metoclient";
import options from "../metoclient_config.json";

export default {
  name: "WeatherMap",
  data() {
    return {
      metoclient: null,
    }
  },
  mounted() {
    this.metoclient = new MetOClient(options);
    this.metoclient.render().catch((err) => {
      (console.error || console.log).call(console, err.stack || err);
    });
  },
};
</script>

<template>
  <ul class="nav nav-tabs" id="idTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="fmi-tab" data-bs-toggle="tab" data-bs-target="#fmi" type="button" role="tab" aria-controls="fmi" aria-selected="true">Ilmatieteenlaitos</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="windy-tab" data-bs-toggle="tab" data-bs-target="#windy" type="button" role="tab" aria-controls="windy" aria-selected="false">Windy.com</button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="fmi" role="tabpanel" aria-labelledby="fmi-tab">
      <div id="map"></div>
      <div>Ilmatieteenlaitos, <a href="https://www.ilmatieteenlaitos.fi/avoin-data">avoin data</a></div>
    </div>
    <div class="tab-pane fade" id="windy" role="tabpanel" aria-labelledby="windy-tab">
      <iframe width="100%" height="600px"
        src="https://embed.windy.com/embed2.html?lat=65.321&lon=25.928&detailLat=62.930&detailLon=24.554&width=290&height=580&zoom=5&level=surface&overlay=temp&product=ecmwf&menu=&message=true&marker=&calendar=now&pressure=true&type=map&location=coordinates&detail=&metricWind=m%2Fs&metricTemp=%C2%B0C&radarRange=-1"
        frameborder="0"/>
      <div>Lähde, <a href="https://www.ilmatieteenlaitos.fi/avoin-data">Windy.com</a></div>
    </div>
  </div>
</template>

<style scoped>
@import url("~@/assets/css/metoclient.css");

#map {
  height: 600px;
  width: 100%;
  position: relative;
}
</style>
